export default class VerifyService {
    constructor({ $axios }) {
        this.$axios = $axios;
    }

    verifyEmail(code) {
        const config = {
            method: 'post',
            url: '/verify/email',
            data: {
                code,
            },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    makeCreditReport() {
        const config = {
            method: 'post',
            url: '/verify/credit-report',
        };

        return this.$axios(config).then(response => response.data.data);
    }

    makeBusRegisterCheck() {
        const config = {
            method: 'post',
            url: '/verify/bus-register-check',
        };

        return this.$axios(config).then(response => response.data.data);
    }
}
