import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_4de1dc0a from 'nuxt_plugin_sentryserver_4de1dc0a' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_2faf2afc from 'nuxt_plugin_sentryclient_2faf2afc' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_recaptcha_01dc8496 from 'nuxt_plugin_recaptcha_01dc8496' // Source: ./recaptcha.js (mode: 'all')
import nuxt_plugin_vuesocialsharingplugin_097f1e3f from 'nuxt_plugin_vuesocialsharingplugin_097f1e3f' // Source: ./vue-social-sharing-plugin.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_674415ee from 'nuxt_plugin_cookieuniversalnuxt_674415ee' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_pluginutils_ea6a7e96 from 'nuxt_plugin_pluginutils_ea6a7e96' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_8dc41680 from 'nuxt_plugin_pluginrouting_8dc41680' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_7e05a2a2 from 'nuxt_plugin_pluginmain_7e05a2a2' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_axios_80c87b74 from 'nuxt_plugin_axios_80c87b74' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_nuxtsvgsprite_70cffde4 from 'nuxt_plugin_nuxtsvgsprite_70cffde4' // Source: ./nuxt-svg-sprite.js (mode: 'all')
import nuxt_plugin_i18n_1fba523a from 'nuxt_plugin_i18n_1fba523a' // Source: ../plugins/i18n.js (mode: 'all')
import nuxt_plugin_axios_5659d192 from 'nuxt_plugin_axios_5659d192' // Source: ../plugins/axios.js (mode: 'all')
import nuxt_plugin_siemaclient_efb722ea from 'nuxt_plugin_siemaclient_efb722ea' // Source: ../plugins/siema.client.js (mode: 'client')
import nuxt_plugin_VueGtmclient_263f6eae from 'nuxt_plugin_VueGtmclient_263f6eae' // Source: ../plugins/VueGtm.client.js (mode: 'client')
import nuxt_plugin_loadLocalStorageDataclient_4d6e6834 from 'nuxt_plugin_loadLocalStorageDataclient_4d6e6834' // Source: ../plugins/loadLocalStorageData.client.js (mode: 'client')
import nuxt_plugin_jwtErrorHandlerclient_4d1b8271 from 'nuxt_plugin_jwtErrorHandlerclient_4d1b8271' // Source: ../plugins/jwtErrorHandler.client.js (mode: 'client')
import nuxt_plugin_auth_7543862a from 'nuxt_plugin_auth_7543862a' // Source: ./auth.js (mode: 'all')
import nuxt_plugin_mcGlobals_fda1091a from 'nuxt_plugin_mcGlobals_fda1091a' // Source: ../plugins/mcGlobals.js (mode: 'all')
import nuxt_plugin_sentryclient_418f831a from 'nuxt_plugin_sentryclient_418f831a' // Source: ../plugins/sentry.client.js (mode: 'client')
import nuxt_plugin_gmapVueclient_2ee28d99 from 'nuxt_plugin_gmapVueclient_2ee28d99' // Source: ../plugins/gmapVue.client.js (mode: 'client')
import nuxt_plugin_floatingVueclient_90a37070 from 'nuxt_plugin_floatingVueclient_90a37070' // Source: ../plugins/floatingVue.client.js (mode: 'client')
import nuxt_plugin_vuelidateclient_39753119 from 'nuxt_plugin_vuelidateclient_39753119' // Source: ../plugins/vuelidate.client.js (mode: 'client')
import nuxt_plugin_VueSkeletonLoaderclient_1ea386ee from 'nuxt_plugin_VueSkeletonLoaderclient_1ea386ee' // Source: ../plugins/VueSkeletonLoader.client.js (mode: 'client')
import nuxt_plugin_localeMatcherclient_1164f5c0 from 'nuxt_plugin_localeMatcherclient_1164f5c0' // Source: ../plugins/localeMatcher.client.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: function() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });
    const countryCode = this.$store.getters.siteCountryCodeWithFallback;

    const structuredDataAddress = {
      '@type': 'PostalAddress',
      contactType: 'customer service',
      streetAddress: '22-23 Stationsgatan',
      postalCode: '75340',
      addressCountry: 'SE' };

    const title = `${this.$config.siteName} – ${this.$t('common.default_seo_page_title_suffix')}`;
    const description = this.$t('common.default_seo_page_description', {
      siteName: this.$config.siteName,
      siteCountry: this.$t(`localization.countries.${countryCode}`) });

    const ogImageUrl = `${this.$config.siteUrl}/facebook-default-og-image.jpg`;

    // Since we show Magazine in English even for DE, we must make sure to set the
    // canonical URL to / (=en) to avoid duplicate content problems
    const canonical = i18nHead.link.find((l) => l.hid === 'i18n-can');

    if (this.$i18n.locale === 'de' && this.getRouteBaseName() === 'magazine') {
      canonical.href = canonical.href.replace(/^\/de\//, '/');
    }

    // Makes sure the canonical is absolute
    if (canonical.href.substr(0, 4) !== 'http' && this.$config.siteUrl) {
      canonical.href = `${this.$config.siteUrl}${canonical.href}`;
    }

    return {
      title,
      titleTemplate: `%s – ${this.$config.siteName}`,
      htmlAttrs: {
        ...i18nHead.htmlAttrs },

      meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1.0"' },
      { name: 'format-detection', content: 'telephone=no' },
      { name: 'msapplication-TileColor', content: '#0E181E' }, // See namedColors.primary
      { name: 'msapplication-TileImage', content: '/tile-icon.png' },
      {
        hid: 'description',
        name: 'description',
        content: description },

      { hid: 'og-type', property: 'og:type', content: 'website' },
      { hid: 'og-title', property: 'og:title', content: title },
      { hid: 'og-description', property: 'og:description', content: description },
      { hid: 'og-site_name', property: 'og:site_name', content: this.$config.siteName },
      { hid: 'og-image', property: 'og:image', content: ogImageUrl },
      { hid: 'og-image-secure', property: 'og:image:secure_url', content: ogImageUrl },
      { hid: 'og-image-width', property: 'og:image:width', content: 1200 },
      { hid: 'og-image-height', property: 'og:image:height', content: 627 },

      ...i18nHead.meta],

      link: [
      { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico?v=3' },
      { rel: 'apple-touch-icon-precomposed', type: 'image/png', href: '/apple-touch-icon-precomposed.png' },
      {
        rel: 'preconnect',
        href: this.$config.mcBackendUrl,
        crossorigin: 'anonymous' },

      {
        rel: 'preconnect',
        href: this.$config.cmsApiUrl,
        crossorigin: 'anonymous' },

      {
        rel: 'preload',
        href: require('~/assets/fonts/poppins/Poppins-Light.woff2'),
        as: 'font',
        type: 'font/woff2',
        crossorigin: 'anonymous' },

      {
        rel: 'preload',
        href: require('~/assets/fonts/poppins/Poppins-Bold.woff2'),
        as: 'font',
        type: 'font/woff2',
        crossorigin: 'anonymous' },

      {
        rel: 'preload',
        href: require('~/assets/fonts/poppins/Poppins-Regular.woff2'),
        as: 'font',
        type: 'font/woff2',
        crossorigin: 'anonymous' },

      {
        rel: 'preload',
        href: require('~/assets/fonts/poppins/Poppins-SemiBold.woff2'),
        as: 'font',
        type: 'font/woff2',
        crossorigin: 'anonymous' },

      // Remove 'alternate' links since we markup alternates in our sitemaps instead
      ...i18nHead.link.filter((m) => m.rel !== 'alternate')],

      script: [
      // New Relic Browser Monitoring
      {
        hid: 'newrelic',
        src: this.$config.newRelicScriptUrl,
        defer: true,
        type: 'text/javascript',
        charset: 'utf-8' },

      {
        hid: 'ld-json',
        type: 'application/ld+json',
        json: [
        {
          '@context': 'http://schema.org',
          '@type': 'AutoRental',
          name: this.$config.siteName,
          url: this.$config.siteUrl,
          sameAs: ['https://www.facebook.com/mycamper'],
          email: 'support@mycamper.ch',
          telephone: '+46187512180',
          address: structuredDataAddress },

        {
          '@context': 'https://schema.org/',
          '@type': 'Product',
          name: 'MyCamper',
          aggregateRating: {
            '@type': 'AggregateRating',
            bestRating: '5',
            worstRating: '0',
            ratingValue: this.$store.getters.userReviews.averageScore,
            reviewCount: this.$store.getters.userReviews.reviewCountRounded } }] },

      // This script is a fix to properly attribute traffic & events to GoogleAds in our GTM tracking
      // Without it, that traffic is attributed to organic
      {
        hid: 'gtm-originalLocation',
        innerHTML:
        'window.dataLayer = window.dataLayer || []; window.dataLayer.push({originalLocation: document.location.protocol + "//" + document.location.hostname + document.location.pathname + document.location.search});' }],

      __dangerouslyDisableSanitizersByTagID: {
        'gtm-originalLocation': ['innerHTML'],
        newrelic: ['script'] } };
  },

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_4de1dc0a === 'function') {
    await nuxt_plugin_sentryserver_4de1dc0a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_2faf2afc === 'function') {
    await nuxt_plugin_sentryclient_2faf2afc(app.context, inject)
  }

  if (typeof nuxt_plugin_recaptcha_01dc8496 === 'function') {
    await nuxt_plugin_recaptcha_01dc8496(app.context, inject)
  }

  if (typeof nuxt_plugin_vuesocialsharingplugin_097f1e3f === 'function') {
    await nuxt_plugin_vuesocialsharingplugin_097f1e3f(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_674415ee === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_674415ee(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_ea6a7e96 === 'function') {
    await nuxt_plugin_pluginutils_ea6a7e96(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_8dc41680 === 'function') {
    await nuxt_plugin_pluginrouting_8dc41680(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_7e05a2a2 === 'function') {
    await nuxt_plugin_pluginmain_7e05a2a2(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_80c87b74 === 'function') {
    await nuxt_plugin_axios_80c87b74(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtsvgsprite_70cffde4 === 'function') {
    await nuxt_plugin_nuxtsvgsprite_70cffde4(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_1fba523a === 'function') {
    await nuxt_plugin_i18n_1fba523a(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_5659d192 === 'function') {
    await nuxt_plugin_axios_5659d192(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_siemaclient_efb722ea === 'function') {
    await nuxt_plugin_siemaclient_efb722ea(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_VueGtmclient_263f6eae === 'function') {
    await nuxt_plugin_VueGtmclient_263f6eae(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_loadLocalStorageDataclient_4d6e6834 === 'function') {
    await nuxt_plugin_loadLocalStorageDataclient_4d6e6834(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_jwtErrorHandlerclient_4d1b8271 === 'function') {
    await nuxt_plugin_jwtErrorHandlerclient_4d1b8271(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_7543862a === 'function') {
    await nuxt_plugin_auth_7543862a(app.context, inject)
  }

  if (typeof nuxt_plugin_mcGlobals_fda1091a === 'function') {
    await nuxt_plugin_mcGlobals_fda1091a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_418f831a === 'function') {
    await nuxt_plugin_sentryclient_418f831a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gmapVueclient_2ee28d99 === 'function') {
    await nuxt_plugin_gmapVueclient_2ee28d99(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_floatingVueclient_90a37070 === 'function') {
    await nuxt_plugin_floatingVueclient_90a37070(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuelidateclient_39753119 === 'function') {
    await nuxt_plugin_vuelidateclient_39753119(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_VueSkeletonLoaderclient_1ea386ee === 'function') {
    await nuxt_plugin_VueSkeletonLoaderclient_1ea386ee(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_localeMatcherclient_1164f5c0 === 'function') {
    await nuxt_plugin_localeMatcherclient_1164f5c0(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
